<template>
  <div class="faq--container">
    <h1>ВАЖНО</h1>
    <h2>Памятка пользователя</h2>
    <p>Назначение сервиса – защита прав и здоровья участников образовательного процесса, обеспечение сохранности имущества учреждения, ознакомление с формой и содержанием образования<br>
    </p>
    <b>Пользователь обязан:<br></b>
    <ul>
      <li>
        - Не передавать ключи доступа посторонним! Использование сервиса, помимо работников образования, допустимо родителями (законными представителями) обучающихся
      </li>
      <li>
        - Не создавать копии любыми способами! В случае необходимости нужный фрагмент архива выдается по запросу, либо организуется коллективный просмотр в учреждении<br>

      </li>
      <li>
        - Не распространять фото видео материалы, полученные в сервисе, в том числе в сети Интернет!<br>

      </li>
      <li>
        - Не допускать вмешательства в образовательный процесс на основании информации, полученной в сервисе! Не предпринимать попыток корректирования профессиональной деятельности педагогов!<br>

      </li>
      <li>
        - Не использовать сервис для идентификации изображенных на видео людей без их письменного согласия!<br>

      </li>
      <li>
        В случае неоднократного нарушения правил, сервис оставляет за собой право на прекращение предоставления услуг без объяснения причин.
        <br> <strong>Используйте возможности во благо, а не во вред!</strong>

      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "SettingsFaqView"
}
</script>

<style scoped lang="scss">
.faq--container{
  width: 335px;
  height: 600px;
  outline: 0;
  border: 0;
  overflow: scroll;
  margin-left: auto;
  margin-right: auto;
  margin-top: 24px;
  padding-bottom: 200px;


  @include for-desktop-devices{
    margin-right: 0;
    margin-left: 60px;
    margin-top: 26px;
    background: #F8F8F8;
    border-radius: 4px;
    overflow: hidden;
  }

  &::-webkit-scrollbar{
    display: none;
  }




  h1{
    font-family: Roboto, "sans-serif";
    font-weight: bold;
    font-size: 18px;
    color: #000000;
    line-height: 24px;
  }

  h2{
    margin-top: 9px;
    font-family: Roboto, "sans-serif";
    font-weight: 300;
    font-size: 16px;
    letter-spacing: 0;
  }

  p{
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0;
  }

  ul {
    margin-top: 17px;
    list-style-type: none;
    li {
      margin-bottom: 15px;
      position: relative;
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 17px;
      /* or 142% */


      color: #000000;

      &:last-child {
        margin-bottom: 0;
      }

      strong{
        font-family: Roboto, "sans-serif";
        font-weight: 500;
        font-size: 12px;
        color: black;
        letter-spacing: 0;
      }
    }
  }

  b{
    font-family: Roboto, "sans-serif";
    font-weight: 500;
    font-size: 12px;
    color: black;
    letter-spacing: 0;
  }
}
</style>
